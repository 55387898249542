import React, { useState } from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/header/logo.svg";
import img1 from "../../../assets/img/footer/1.png";
import img2 from "../../../assets/img/footer/2.png";
import img3 from "../../../assets/img/footer/3.png";
import img4 from "../../../assets/img/footer/4.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const [ email, setEmail ] = useState('');
  const send = () => {
    if (email !== '') {
      setEmail('');
    }
  };
  return (
    <div className={classes.footer}>
      <div className={classes.footerBody}>
        <div className="container">
          <div className={classes.footerRow}>
            <div className={classes.footerRowColumn}>
              <div className={classes.footerRowColumnLogo}>
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
              {/* <div className={classes.footerRowColumnItem}>
                {t("footer_legal_doc")}
              </div>
              <div className={classes.footerRowColumnItem}>
                {t("cookies_policy")}
              </div> */}
              <a className={classes.footerRowColumnItem} href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                {t("privacy_policy")}
              </a>
              <a className={classes.footerRowColumnItem} href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                {t("terms_conditions")}
              </a>
              <a className={classes.footerRowColumnItem} href="/docs/AML_POLICY.pdf" target="_blank">
                AML
              </a>
            </div>
            <div className={classes.footerRowColumn}>
              <div className={classes.footerRowColumnItem}>
                <Link to="/tools">{t("tools_platform")}</Link>
              </div>
              <div className={classes.footerRowColumnItem}>
                <Link to="/affiliates">{t("affiliates_partners")}</Link>
              </div>
              <div className={classes.footerRowColumnItem}>
                <Link to="/about">{t("about_us")}</Link>
              </div>
              <div className={classes.footerRowColumnItem}>
                <Link to="/company">{t("company_support1")}</Link>
              </div>
            </div>
            <div className={classes.footerRowColumn}>
              {/* <div className={classes.footerRowColumnItem}>
                {t("open_demo_account")}
              </div> */}
              <a href="https://client.wealth-wise.one/signup?lang=en" className={classes.footerRowColumnItem}>
                {t("open_live_account")}
              </a>
              {/* <div className={classes.footerRowColumnItem}>
                {t("affiliate_program")}
              </div> */}
            </div>
            <div className={classes.footerRowColumn}>
              <div className={classes.footerRowColumnItem}>
                support@wealth-wise.io
              </div>
              <div className={classes.footerRowColumnItem}>
                +442080979926
              </div>
            </div>
            <div className={classes.footerRowColumnForm}>
              <div className={classes.footerRowColumnFormTitle}>
                {t("footer_text_1")}
              </div>
              <div className={classes.footerRowColumnFormInputs}>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder={t("input_mail_placeholder")} />
                <button onClick={send}>{t("form_send")}</button>
              </div>
              <div className={classes.footerRowColumnFormPays}>
                <img src={img1} alt="" />
                <img src={img2} alt="" />
                <img src={img3} alt="" />
                <img src={img4} alt="" />
              </div>
            </div>
            <div className={classes.footerRowColumnCopyright}>
              <div className={classes.footerRowColumnCopyrightTitle}>
                {t("risk_warning")}
              </div>
              <div className={classes.footerRowColumnCopyrightSubtitle}>
                {t("risk_warning_desc")}
              </div>
              <div className={classes.footerRowColumnCopyrightText}>
                {t("footer_copyright")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import classes from './OurPlatform.module.scss';
import google_play from '../../../assets/img/Tools/dwd_gplay.png';
import app_store from '../../../assets/img/Tools/dwd_appstore.png';
import mac from '../../../assets/img/Tools/dwd_mac.png';
import windows from '../../../assets/img/Tools/dwd_windows.png';
import i18next from "i18next";

const OurPlatform = () => {
    return (
        <div className="container">
            <div className={classes.ourPlatform}>
                <div className={[classes.header, 'simpleTitle'].join(' ')}>
                    <span>{i18next.t('tools.our_platform_title')}</span>
                </div>
                <div className={classes.text}>
                    {i18next.t('tools.our_platform_text')}
                </div>
                <div className={classes.icons}>
                    <img src={google_play} alt='' />
                    <img src={app_store} alt='' />
                    <img src={mac} alt='' />
                    <img src={windows} alt='' />
                </div>
            </div>
        </div>
    );
};

export default OurPlatform;
